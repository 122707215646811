import { ReactNode } from 'react';

import classNames from 'classnames';

import ImageProxy, { ImageProxyProps } from '@next-image-proxy';

import Breadcrumbs, {
  BreadcrumbsProps,
} from '@store:web/components/common/Breadcrumbs/Breadcrumbs';

export interface BasePageHeaderProps {
  title?: ReactNode;
  icon?: ReactNode;
  className?: string;
  children?: ReactNode;
  disableSeoTags?: boolean;
  decorImage?: {
    mobile: ImageProxyProps['src'];
    desktop: ImageProxyProps['src'];
  };
  breadcrumbs?: BreadcrumbsProps['items'];
  disableBanner?: boolean;
  breadcrumbsCurrentPathname?: string;
}

const BasePageHeader = ({
  title,
  icon,
  className,
  decorImage,
  breadcrumbs,
  disableSeoTags,
  disableBanner,
  breadcrumbsCurrentPathname,
}: BasePageHeaderProps) => {
  const HeadingTag = disableSeoTags ? 'span' : 'h1';

  return (
    <div
      className={classNames(
        'w-full relative z-0',
        {
          'pt-5': disableBanner,
        },
        className,
      )}
    >
      {!disableBanner && (
        <header className="relative flex flex-col items-center bg-primary-100 min-h-[6.25rem] sm:min-h-[3.75rem] md:min-h-[5rem] lg:min-h-[6rem] xl:min-h-[7.5rem] 2xl:min-h-[8.5rem] 3xl:min-h-[11.5rem]">
          <div className="relative z-10 flex-grow py-2.5 store-container-px flex flex-col justify-center items-center text-center w-full max-w-store-container">
            {icon && (
              <span className="icon aspect-square absolute left-0 w-auto h-full my-auto top-0 bottom-0">
                {icon}
              </span>
            )}

            <div className="relative flex flex-col items-center gap-1 lg:gap-2 xl:gap-2.5 3xl:gap-3 max-sm:max-w-[12.875rem]">
              <HeadingTag className="text-sm font-extrabold md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl 3xl:text-5xl">
                {title}
              </HeadingTag>
            </div>
          </div>

          <ImageProxy
            alt=""
            priority
            sizes="20vw"
            src={decorImage?.desktop}
            fill
            className="max-sm:hidden !left-auto !w-auto"
          />
          <ImageProxy
            alt=""
            priority
            src={decorImage?.mobile}
            sizes="20vw"
            fill
            className="sm:hidden !left-auto !w-auto"
          />
        </header>
      )}

      {!!breadcrumbs?.length && (
        <Breadcrumbs
          currentPathname={breadcrumbsCurrentPathname}
          className="store-container-px py-2"
          items={breadcrumbs}
        />
      )}
    </div>
  );
};

export default BasePageHeader;
